import InputField from "../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginSchema } from "../../utils/validationSchema";
import logo from "../../assets/images/logo.svg";
import bannerSrc from "../../assets/images/LoginPicture.png";
import { useTranslation } from "react-i18next";
import Button from "../../components/AuthButton/Button";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";
import { Footer } from "../../components";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InvoicePaymentModal from "../../components/PaymentModal/InvoicePaymentModal";

const Login = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { pathname, state } = useLocation();
  const [signUpPath, setSignUpPath] = useState("");
  const [forgotPasswordpath, setForgotPasswordPath] = useState("");
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const currentTime = new Date();
  const timeDifferenceInMinutes = currentTime.getTimezoneOffset();

  useEffect(() => {
    if (pathname === "/login") {
      setSignUpPath("/signup");
      setForgotPasswordPath("/forgotPassword");
    } else {
      setSignUpPath("/home/signup");
      setForgotPasswordPath("/home/forgotPassword");
    }
  }, [pathname]);

  const checkTrialStatus = (createdOn) => {
    const creationDate = new Date(createdOn);
    const trialPeriod = 14 * 24 * 60 * 60 * 1000;
    const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
    const currentDate = new Date();
    return {
      isTrialExpired: currentDate.getTime() > trialEndDate.getTime(),
      trialEndDate,
    };
  };

  const handleLogin = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        email: values.email.toLowerCase(),
        password: values.password,
        timeDifference: timeDifferenceInMinutes,
        isAdmin: false,
      };

      const response = await api.post(apiEndpoint.signIn, data);

      if (response.data.responseStatus === 1) {
        const { userDetails } = response.data;

        const { isTrialExpired } = checkTrialStatus(userDetails.createdOn);

        localStorage.setItem("token", userDetails.accessToken);
        const userData = {
          name: userDetails.name.split(" ")[0],
          age: userDetails.age,
          gender: userDetails.gender,
          email: userDetails.email,
          createdAt: userDetails.createdOn,
          subscriptionId: userDetails.subscriptionId,
          subscriptionStatus: userDetails.subscriptionStatus,
          cancelAt: userDetails.cancelAt,
        };

        localStorage.setItem("formData", JSON.stringify(userData));

        if (
          userDetails.customerId &&
          userDetails.invoice?.length > 0 &&
          userDetails.invoice[0]?.invoiceLink
        ) {
          setInvoiceLink(userDetails.invoice[0].invoiceLink);
          setShowInvoiceModal(true);
          return;
        }

        try {
          const subscriptionResponse = await api.post(
            apiEndpoint.getSubscriptionDetails,
            {
              headers: {
                Authorization: `Bearer ${userDetails.accessToken}`,
              },
            }
          );

          if (subscriptionResponse.data.responseStatus === 1) {
            const subscriptionData = subscriptionResponse.data.response;
            if (subscriptionData) {
              const subscriptionDetails = {
                subscriptionId: subscriptionData.id,
                startDate: subscriptionData.start_date,
                status: subscriptionData.status,
                cancelAt: subscriptionData.cancel_at_period_end
                  ? new Date(subscriptionData.cancel_at * 1000)
                  : subscriptionData.cancel_at,
                canceled: subscriptionData.cancel_at_period_end,
                plan: {
                  id: subscriptionData.plan.id,
                  name: subscriptionData.plan.nickname || "Monthly Plan",
                  amount: subscriptionData.plan.amount / 100,
                  currency: subscriptionData.plan.currency,
                },
                nextBillingDate: subscriptionData.current_period_end * 1000,
              };
              localStorage.setItem(
                "subscriptionData",
                JSON.stringify(subscriptionDetails)
              );
            }
          }
        } catch (err) {
          console.error("Error fetching subscription data:", err);
        }

        const subscriptionData = JSON.parse(
          localStorage.getItem("subscriptionData")
        );

        const hasActiveSubscription =
          subscriptionData?.status === "active" &&
          (!subscriptionData.cancelAt ||
            subscriptionData.cancelAt > new Date());

        if (isTrialExpired && !hasActiveSubscription) {
          navigation("/subscription", { state: { path: state?.path } });
        } else if (state?.path) {
          navigation(state?.path);
        } else if (pathname === "/home/login") {
          navigation("/");
        } else {
          navigation("/diagnosis");
        }
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error(t("loginError"));
    }
    setIsLoading(false);
  };

  const handleModalClose = (wasPaymentInitiated) => {
    setShowInvoiceModal(false);

    if (wasPaymentInitiated || !invoiceLink) {
      if (state?.path) {
        navigation(state?.path);
      } else if (pathname === "/home/login") {
        navigation("/");
      } else {
        navigation("/diagnosis");
      }
    }
  };

  return (
    <>
      <div className="flex h-screen items-stretch text-black">
        <div className="w-screen h-full overflow-y-scroll sm:w-1/2 xl:p-16 p-8">
          <Link
            to="/"
            className="flex items-center gap-2 xl:justify-start justify-center"
          >
            <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
            <p className="xl:text-[20px] text-[14px] font-[700] p-0">
              SUNNDIO Health
            </p>
          </Link>
          <div>
            <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">
              {t("welcome")}
            </p>
          </div>
          <div>
            <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-6 mt-4">
              {t("loginWith")}
            </p>
          </div>
          <Formik
            onSubmit={handleLogin}
            initialValues={initialValues}
            validationSchema={loginSchema}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col items-center sm:block h-42 sm:pl-16.5 w-full">
                <div className="w-full xl:mt-8 mt-6">
                  <InputField id="email" label={t("emailAddress")}>
                    {errors.email && touched.email ? (
                      <div className="text-red-500">{errors.email}</div>
                    ) : null}
                  </InputField>

                  <InputField id="password" label={t("password")}>
                    {errors.password && touched.password ? (
                      <div className="text-red-500">{errors.password}</div>
                    ) : null}
                    <p className="w-full mt-5 text-end block font-bold text-[#3b00d1]">
                      <Link to={forgotPasswordpath}>{t("forgotPassword")}</Link>
                    </p>
                  </InputField>
                  <Button text={t("login")} isLoading={isLoading}/>
                </div>
              </Form>
            )}
          </Formik>

          <p className="text-[16px] text-center font-[400]">
            {t("notAccount")}{" "}
            <button
              onClick={() => navigation(signUpPath, { state: state?.path })}
              className="text-[#3b00d1]"
            >
              {t("signUpWithEmail")}
            </button>
          </p>
        </div>
        <div className="justify-center items-center w-1/2 hidden sm:flex">
          <img className="w-[100%] h-full" src={bannerSrc} alt="Login" />
        </div>
      </div>
      <InvoicePaymentModal
        isOpen={showInvoiceModal}
        onClose={handleModalClose}
        invoiceLink={invoiceLink}
      />
      <Footer />
    </>
  );
};

export default Login;
