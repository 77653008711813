import React from 'react';
import "./button.scss"
import { RiLoader4Line } from "react-icons/ri";

const Button = ({ text, onClick, isLoading, className }) => {


    return (
        <>
            <button
                type="submit"
                disabled={isLoading}
                className="ButtonLogin"
            >
                {isLoading? (<RiLoader4Line color='white' size={25} className='animate-spin place-self-center' />):
                (<p className='ButtonTextLogin'>{text.toUpperCase()}</p>)}
                
            </button>
        </>
    )
}

export default Button
