import React, { useEffect, useState } from "react";
import axios from "axios";
import "./chatbot.css";
import { Link, useNavigate } from "react-router-dom";

const liveUrl = "https://sunndio-chatbot-app-gecshzb2adbyduhm.eastus-01.azurewebsites.net"

// const liveUrl = "http://44.221.197.214:5000"

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);

  const getCurrentTime = () => {
    const date = new Date();
    let hour = date.getHours();
    const minute = date.getMinutes().toString().padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minute} ${ampm}`;
  };

  const navigate = useNavigate();

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    const time = getCurrentTime();

    const userMessage = { text: messageText, time, sender: "user" };
    setMessages((prev) => [userMessage, ...prev]);
    setLoading(true);

    try {
      const newForm = new FormData();
      newForm.append("msg", messageText);
      if (session) newForm.append("session", session);

      const response = await axios.post(
        `${liveUrl}/ask`,
        newForm
      );
      setSession(response?.data?.session);

      const { answer, options } = JSON.parse(response.data.answer);

      const botMessage = {
        text: answer,
        options: options
          ? Object.entries(options).map(([key, value], index) => ({
              label: String.fromCharCode(65 + index),
              value: value,
            }))
          : [],
        time: getCurrentTime(),
        sender: "bot",
      };

      setMessages((prev) => [botMessage, ...prev]);
    } catch (error) {
      console.error("Error fetching response from bot:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(input);
    setInput("");
  };

  const handleOptionClick = (optionValue) => {
    sendMessage(optionValue);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) navigate("/");
  }, [navigate]);

  useEffect(() => {
    const sessionClear = async () => {
      await axios.post(`${liveUrl}/clear-session`);
      setSession(null);
    };
    sessionClear();
  }, []);

  useEffect(() => {
    const greetings = async () => {
      const response = await axios.post(
        `${liveUrl}/ask`,
        new FormData()
      );
      setSession(response?.data?.session);

      const { answer, options } = JSON.parse(response.data.answer);

      const botMessage = {
        text: answer,
        options: options
          ? Object.entries(options).map(([key, value], index) => ({
              label: String.fromCharCode(65 + index),
              value: value,
            }))
          : [],
        time: getCurrentTime(),
        sender: "bot",
      };

      setMessages([botMessage]);
    };
    greetings();
  }, []);

  return (
    <div className="chat-container">
      <div className="chat-box">
        <Link to="/" className="chat-header">
          <div>
            <img
              src="picture/sunndio11.png"
              alt="Bot"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "1px solid #3B00D1",
              }}
            />
          </div>
          <div>
            SUNNDIO BOT
            <div id="sub-heading">Physiotherapist</div>
          </div>
        </Link>
        <div className="msg_card_body">
          {loading && (
            <div className="d-flex justify-content-start mb-2">
              <div className="img_cont_msg">
                <img
                  src="picture/sunndio11.png"
                  alt="Bot logo"
                  className="rounded-circle user_img_msg"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "80px",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "blue",
                      animation: "dot 1s infinite alternate",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      animation: "dot 1s 0.2s infinite alternate",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "yellow",
                      animation: "dot 1s 0.4s infinite alternate",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "green",
                      animation: "dot 1s 0.6s infinite alternate",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                marginBottom: "10px",
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              {message.sender === "bot" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src="picture/sunndio11.png"
                    alt="Bot"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      border: "1px solid #3B00D1",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  maxWidth: "70%",
                  backgroundColor:
                    message.sender === "user" ? "#E9EAEC" : "#e1f5fe",
                  padding: "12px 18px",
                  borderRadius: "20px",
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                <p>{message.text}</p>
                <div>
                  {message.options?.map((option, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleOptionClick(option.value)}
                      style={{
                        margin: "5px",
                        padding: "5px 10px",
                        cursor: "pointer",
                        backgroundColor: "#3B00D1",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                      }}
                    >
                      {option.label}: {option.value}
                    </button>
                  ))}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    color: "#666",
                    marginTop: "5px",
                    textAlign: "right",
                  }}
                >
                  {message.time}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="card-footer">
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", width: "100%" }}
          >
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className="type_msg"
              required
            />
               <button type="submit" className="send_btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M2 21l21-9L2 3v7l15 2-15 2z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
