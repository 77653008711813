import InputField from "../../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import bannerSrc from "../../../assets/images/forgotEmail.png";
import { useTranslation } from "react-i18next";
import Button from "../../../components/AuthButton/Button";
import { api } from "../../../api";
import { apiEndpoint } from "../../../api/apiEndpoints";
import { Footer } from "../../../components";
import { toast } from "react-toastify";
import { forgotPassword } from "../../../utils/validationSchema";
import { useState } from "react";

const ForgotPassword = () => {

    const { t } = useTranslation()
    const navigation = useNavigate()
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        email: "",
    }


    const handleEmailSend = async (values) => {
        setIsLoading(true);
        const data = {
            email: values.email.toLowerCase(),
        };

        const response = await api.post(apiEndpoint.forgotPassword, data);

        if (response.data.responseStatus === 1) {
            toast.success("Otp send to the email")
            if (pathname === "/home/forgotPassword") {
                navigation("/home/updatePassword", { state: response.data.userId })
            } else {
                navigation("/updatePassword", { state: response.data.userId })
            }

        } else {
            toast.error(response.data.error)
        }

        setIsLoading(false);
    };

    return (
        <>
            <div className="flex h-screen text-black">
                <div className="w-screen h-full overflow-y-scroll sm:w-1/2 xl:p-10 p-8">
                    <Link to="/" className="flex items-center gap-2 xl:justify-start justify-center">
                        <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
                        <p className="xl:text-[20px] text-[14px] font-[700] p-0" >
                            SUNNDIO Health
                        </p>
                    </Link>
                    <div>
                        <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">{t("forgotPasswordQuestion")}</p>
                    </div>
                    <div>
                        <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-6 mt-4">{t("enterEmailSendOtp")}</p>
                    </div>
                    <Formik
                        onSubmit={handleEmailSend}
                        initialValues={initialValues}
                        validationSchema={forgotPassword}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col items-center sm:block h-42 sm:pl-16.5 w-full">
                                <div className="w-full xl:mt-8 mt-6">
                                    <InputField id="email" label={t("emailAddress")}>
                                        {errors.email && touched.email ? (
                                            <div className="text-red-500">{errors.email}</div>
                                        ) : null}
                                    </InputField>

                                    <Button text={t("continue")} isLoading={isLoading} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="justify-center items-center w-1/2 hidden sm:flex">
                    <img
                        className="w-[100%] h-full"
                        src={bannerSrc}
                        alt="Forgot email"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ForgotPassword;
