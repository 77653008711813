export const es = {
  translation: {
    home: "Home",
    Button: "Button",
    injuryHeading: "¿Qué lesión tengo? ¿Qué puedo hacer para recuperarme?",
    homeButton: "INICIAR DIAGNÓSTICO",
    homeTitleDescription:
      "Impulsando la salud y la movilidad con inteligencia artificial",
    homeQuestion: `Siento un dolor/molestia.`,
    homeQuestion1: "¿Qué me pasa?",
    homeButtonText: "EMPEZAR EVALUACIÓN",

    mainHeading: "RESUMEN DE DIAGNÓSTICO",
    subHeading: "La evaluación consta de 3 partes:",
    listPara1: "1. Conocimiento del paciente",
    listPara2: "2. Comportamiento del dolor",
    listPara3: "3. Preguntas sobre síntomas",
    introButton: "COMENZAR",

    formHeading: "Paciente",
    label1: "¿Cómo te llamas?",
    label2: "¿Qué edad tienes?",
    gender: "Género",
    genderList1: "Hombre",
    genderList2: "Mujer",
    manual_evaluation: "Evaluación manual",
    chatbot_evaluation: "Evaluación del CHAT BOT",
    wanna_continue_with: "Quiero continuar con:",
    submitButton: "Siguiente",
    previousButton: "Anterior",

    welcomeHeading: "Bienvenido",
    welcomeHeadingFemale: "Bienvenida",
    para: "Vamos a comenzar el test con nuestro asistente de inteligencia artificial",
    nextButton: "Siguiente",

    bodyPara1: "¿Dónde sientes el dolor?",
    bodyPara2: "¿Cómo definirías el dolor?",
    bodyPara3: "Concretamente:",

    studyHeading: "Estudio",

    loadingStudioPara1:
      "A continuación necesitamos que respondas algunas preguntas sobre los síntomas.",
    loadingStudioPara2: "Estamos preparando el test de evaluación.",

    questionsHeading: "Preguntas de Evaluación",
    yesButton: "SI",
    noButton: "NO",
    return: "Volver",
    proceed: "Proceder",
    revolve: "Voltear",

    footerText: "Todos los derechos reservados",
    footerTopBarText: "Años facilitando la recuperación de pacientes",

    importantInformation: "Información importante:",
    importantPara1_1: " Sunndio diagnosis es una ",
    importantPara1_2: "herramienta en desarrollo",
    importantPara1_3:
      " por Sunndio health cuyo objetivo es asistir a pacientes y profesionales en el diagnóstico y el tratamiento de patologías musculoesqueléticas leves. ",
    importantPara2:
      "Sunndio health recomienda asistir personalmente al fisioterapeuta en los casos más severos.",
    importantPara3:
      "En ningún caso el diagnóstico y/o tratamiento ofrecido por esta herramienta prevalecerá sobre la opinión de un facultativo.",
    seeAssessmentText: "VER EVALUACIÓN",

    diagnosisResultsHeading: "Los tres diagnósticos más probables:",
    moreInfo: "Más información",

    treatmentLoadingText:
      "Estamos preparando el tratamiento para superar tus barreras físicas",
    descriptionHeading: "¿Qué es?",
    treatmentHeading: "¿Cómo se trata?",
    recoveryTimeHeading: "Tiempo de recuperación estimado:",
    durationLabel: "Con tratamiento",
    treatmentButton: "Empezar tratamiento",
    actions: "Acciones",
    treatments: "Tratamientos",
    treatment: "Tratamiento",
    followup: "Seguimiento",

    program: "Programa",
    treatmentInfo:
      "Bienvenido/a a tu nuevo tratamiento con ejercicios 100% personalizados. <br/><br/>Hemos preparado un plan que consta de varios niveles para poder medir tu grado de dolor y <b>evolucionar con él hasta tu completa recuperación.</b><br/><br/>¡Adelante! Selecciona el nivel 1 para comenzar con tu tratamiento. <br/><br/>Una vez completado el nivel 1, podrás avanzar al siguiente nivel y así sucesivamente.<br/><br/>Siempre que necesites apoyo adicional, tienes a tu disposición nuestro servicio de seguimiento para consultar dudas, solicitar más información. ¡A cualquier problema buscamos la solución más indicada para ti!",
    congratulations: "¡Enhorabuena",
    level: "Nivel",
    exercise: "Ejercicio",
    difficulty: "Dificultad",
    duration: "Duración",
    seeExercise: "Ver ejercicio",
    objective: "Objetivo: Valoración y modulación del dolor",

    nameValidator: "por favor, escriba su nombre",
    ageValidator: "por favor, introduzca su edad",
    genderValidator: "por favor ingrese su género",


    painArea: "Área de Dolor",
    behavior: "Comportamiento",
    definition: "Definición",

    // login

    welcome: "Bienvenido de nuevo",
    loginWith: "Inicia sesión con tu dirección de correo electrónico",
    emailAddress: "Dirección Email",
    password: "Contraseña",
    forgotPassword: "Olvidé mi contraseña",
    login: "Iniciar sesión",
    notAccount: "¿No tienes una cuenta?",
    signUpWithEmail: "Regístrate con tu correo electrónico",

    // signup

    surName: "Nombre y apellidos",
    next: "Próximo",
    yourName: "¿Cómo te llamas?",
    age: "¿Qué edad tienes?",
    phoneNo: "Teléfono No",
    socialMedia:
      "URL de redes sociales (opcional) (Facebook, Twitter, Instagram, etc.)",
    whichGender: "Por favor seleccione su género",
    alreadyAccount: "¿Ya tienes cuenta en Sunndio?",
    missOtp: "¿Extrañas el otp?",
    resend: "Reenviar",
    verify: "Verificar",
    sendOtp:
      "Por favor ingresa el código de 4 dígitos enviado a tu correo electrónico",
    logout: "Cerrar sesión",
    userSettings: "Ajustes",
    userProfile: "Perfil de Usuario",
    diagnostics:"Diagnósticos",
    billing: "Facturación",

    // forgot
    forgotPasswordQuestion: "¿Olvidaste tu contraseña?",
    enterEmailSendOtp:
      "Por favor, ingresa tu dirección de correo electrónico para el proceso de verificación. Te enviaremos un código de 4 dígitos.",
    continue: "Continuar",
    restorePassword: "Restablecer contraseña",
    updatePasswordInfo:
      "Por favor, establece una nueva contraseña para tu cuenta para que puedas iniciar sesión y acceder a todos tus datos.",
    newPassword: "Nueva Contraseña",
    reEnterNewPassword: "Vuelve a ingresar la contraseña",
    or: "O",
    plans: [
      {
        title: "Plan Básico",
        description: [
          "Acceso a características limitadas.",
          "Prueba el servicio durante 14 días.",
          "No se requiere tarjeta de crédito.",
        ],
        price: "Gratis/14 días",
      },
      {
        title: "Plan Mensual",
        description: [
          "Acceso completo a todas las funciones.",
          "Pago mes a mes.",
          "Cancela en cualquier momento.",
        ],
        price: "9.99€/mes",
      },
      {
        title: "Plan Trimestral",
        description: [
          "Ahorra más con un plan de 3 meses.",
          "Acceso completo a todas las funciones.",
          "Ideal para compromisos a corto plazo.",
        ],
        price: "26.99€/3 meses",
      },
      {
        title: "Plan Anual",
        description: [
          "Mejor valor para uso a largo plazo.",
          "Acceso completo a todas las funciones.",
          "Ahorra un 20% en comparación con el plan mensual.",
        ],
        price: "99.99€/año",
      },
    ],
    evaluationValidator: "Se requiere evaluación",
    Continue: "Continuar",
    subscriptionSuccess: "¡Suscripción exitosa!",
    subscriptionSuccessMessage: "Gracias por su suscripción. Su cuenta ha sido activada con éxito.",
    subscriptionDetails: "Detalles de Suscripción",
    currentPlan: "Plan Actual",
    plan:"Plan",
    status: "Estado",
    active: "Activo",
    nextBilling: "Próxima Fecha de Facturación",
    cancelSubscription: "Cancelar Suscripción",
    cancelling: "Cancelando...",
    subscription: {
      currentPlanLabel: "Plan Actual",
      manage: "Gestionar Suscripción",
      renewalDate: "Próxima renovación el {{date}}",
      currentPlan: "Plan Actual: {{plan}}",
      loginRequired: "Por favor inicia sesión para seleccionar un plan",
      selectPlan: "Seleccionar Plan {{plan}}",
      trialExpiresTomorrow: "¡Tu prueba expira mañana! Elige un plan para seguir accediendo a nuestros servicios.",
      trialDaysRemaining: "Te quedan {{days}} días en tu periodo de prueba.",
      choosePlan: "Elige tu Plan",
      loading: "Cargando planes de facturación...",
      features: {
        basic: [
          "Acceso a funciones limitadas",
          "Prueba el servicio durante 14 días",
          "No se requiere tarjeta de crédito"
        ],
        monthly: [
          "Acceso completo a todas las funciones",
          "Pago mes a mes",
          "Cancela cuando quieras"
        ],
        quarterly: [
          "Ahorra más con un plan de 3 meses",
          "Acceso completo a todas las funciones",
          "Soporte prioritario"
        ],
        yearly: [
          "Mejor valor para uso a largo plazo",
          "Acceso completo a todas las funciones",
          "Soporte 24/7"
        ]
      },
      trial: {
        active: "Prueba Activa",
        expired: "Prueba Expirada",
        daysLeft: "{{days}} días restantes",
        upgradeNow: "Actualizar Ahora",
      },
      payment: {
        completePayment: "Completar Pago",
        processing: "Procesando...",
        pay: "Pagar Ahora",
        cancel: "Cancelar",
        success: "Pago Exitoso",
        failed: "Pago Fallido",
      },
      period: {
        month: "/mes",
        quarter: "/trimestre",
        year: "/año",
        trial: "14 días de prueba"
      },
      status: {
        active: "Activo",
        inactive: "Inactivo",
        cancelled: "Cancelado",
        trial: "Prueba"
      }
    },
    invoice: {
      modalTitle: "Pago Pendiente",
      description: "Tienes un pago pendiente que necesita ser procesado. Por favor, haz clic en el botón de abajo para completar tu pago.",
      payNow: "Pagar Ahora",
      close: "Cerrar"
    },
    noActiveSubscription: "Sin Suscripción activa",
    viewPlans: "Ver Planes",
    freeTrial: "Prueba Gratuita",
    startDate: "Fecha de Inicio",
    endDate: "Fecha de Finalización",
    daysRemaining: "Días Restantes",
  },
};
