import React from "react";
import "./VerticalNavBar.scss";
import cx from "classnames";
import { Text } from "..";
import { Link } from "react-router-dom";
import { IoHome, IoCalendar } from "react-icons/io5";

export const VerticalNavBar = (props) => {
  const { className, menuOpen, toggleMenu, config, ...rest } = props;

  const handleCalendarClick = () => {
    window.open("https://calendly.com/samreen-techtimize", "_blank");
  };

  return (
    <>
      <div className={cx({ NavBarOverlay: menuOpen })} onClick={toggleMenu} />
      <div
        className={cx("NavBar", className, { NavBarOpen: menuOpen })}
        {...rest}
      >
        <div className="NavBarHeader">
          <Text className="NavBarHeaderText">{config.title}</Text>
        </div>
        <div className="NavBarUnderline" />
        <div className="h-full flex flex-col">
          <div className="flex items-center justify-center gap-[10px] py-[10px] pr-[10px] transition-transform duration-200 hover:scale-105">
            <IoHome color="black" size={23.5} />
            <Link to={"/"} className="NavBarButtonText">
              Go to Home
            </Link>
          </div>
          <div>
            {config.buttons.map((button) => (
              <div
                key={button.label}
                className={cx("NavBarButtonContainer", {
                  NavBarButtonContainerSelected: button.selected,
                })}
                onClick={button.onClick}
              >
                <img className="NavBarButtonImage" alt="" src={button.svg} />
                <Text className="NavBarButtonText">{button.label}</Text>
              </div>
            ))}
          </div>
          <div
            className={cx("NavBarButtonContainer")}
            style={{ opacity: 1 }}
            onClick={handleCalendarClick}
          >
            <IoCalendar className="NavBarButtonImage" color="black" size={24} />
            <Text className="NavBarButtonText">Calendar</Text>
          </div>
        </div>
      </div>
    </>
  );
};
