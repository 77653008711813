import axios from "axios";
import { getLanguage } from "./utils";
import { addTreatments, setPercentageLoaded, setError } from "../treatments/treatmentsSlice";

export const fetchTreatments = async (dispatch, id, diagnosisTitle) => {

  const response = await axios
    .get(
      `${process.env.REACT_APP_PROD_API
      }/getDiagnosticTreatments/${getLanguage()}/${id}`
      , {
        onDownloadProgress: ({ total, loaded, bytes, ...rest }) => {
          const percentage = Math.round((loaded * 100) / total);
          dispatch(setPercentageLoaded({ percentage }));
        }
      })
    .catch(() => {
      dispatch(setError());
    });
  dispatch(addTreatments({ data: response.data, id, diagnosisTitle }));
};
