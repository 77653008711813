import React, { useState } from "react";
import "./Header.scss";
import { Text } from "../Text";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";

export const Header = ({ headerRightArea }) => {
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("formData");
    localStorage.clear();
    navigate("/");
  };

  const handleProfileClick = () => {
    navigate("/userProfile");
    setIsDropdownOpen(false);
  };

  const handlePricingClick = () => {
    navigate("/subscription");
    setIsDropdownOpen(false);
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
    localStorage.setItem("language", e);
  };

  const handleDiagnostics = () => {
    navigate("/diagnostics");
  };

  // Check if token exists in localStorage
  const token = localStorage.getItem("token");
  return (
    <header className="Header flex-shrink-0">
      <div className="LogoContainer" onClick={() => Navigate("/")}>
        <img
          className="HeaderLogo"
          src={"../../../picture/logo.png"}
          alt={"logo"}
        />
        <Text className={"Text1"} variant={"Heading4"}>
          SUNNDIO
        </Text>
        <Text className={"Text2"} variant={"Heading4"}>
          health
        </Text>
      </div>
      {headerRightArea && (
        <div className="HeaderRightArea">{headerRightArea}</div>
      )}
      <div className="mr-4 flex justify-center items-center">
        <div className="flex items-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 6,
              cursor: "pointer",
            }}
            onClick={() => handleLanguageChange("en")}
          >
            <span style={{ fontSize: "regular" }}>ENG</span>
            <div className="flag-icon">&#x1F1FA;&#x1F1F8;</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleLanguageChange("es")}
          >
            <span style={{ fontSize: "regular" }}>ESP</span>
            <div className="flag-icon">&#x1F1EA;&#x1F1F8;</div>
          </div>
          {!token && (
            <div className="flex items-center pl-[15px] gap-[5px]">
              <button onClick={() => navigate("/home/login")}>Login</button>
            </div>
          )}
        </div>
        {/* User Profile Dropdown (visible if token is present) */}
        {token && (
          <div className="relative">
            <div
              className="flex items-center cursor-pointer justify-center gap-1"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="user-avatar">
                <FaUserCircle size={25} />
              </div>
              <div>
                {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-1 bg-white rounded-md shadow-lg py-1 min-w-[160px] z-10">
                <button
                  className="dropdown-item w-full text-left px-4 py-2 hover:bg-gray-100 text-sm"
                  onClick={handleProfileClick}
                >
                  {t("userProfile")}
                </button>
                <button
                  className="dropdown-item w-full text-left px-4 py-2 hover:bg-gray-100 text-sm "
                  onClick={handlePricingClick}
                >
                  {t("billing")}
                </button>
                <button
                  className="dropdown-item w-full text-left px-4 py-2 hover:bg-gray-100 text-sm "
                  onClick={handleDiagnostics}
                >
                  {t("diagnostics")}
                </button>
                <button
                  className="dropdown-item w-full text-left px-4 py-2 hover:bg-gray-100 text-sm "
                  onClick={handleLogout}
                >
                  {t("logout")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
