import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import painAreaReducer from "./painBody/painAreaSlice";
import painDefiReducer from "./Paindefi/painDefiSlice";
import painFormReducer from "./painForm/painFormSlice";
import painQuestionsReducer from "./questions/painQuestionsSlice";
import painDiagnosisReducer from "./painDiagnosis/painDiagnosisSlice";
import treatmentsReducer from "./treatments/treatmentsSlice";
import UserInfo from "./userInformation/UserInfo";

const painQuestionsPersistConfig = {
  key: 'painQuestions',
  storage,
  whitelist: ['painQuestions', 'painBehaviourId', 'diagonsis'] 
};

const persistedPainQuestionsReducer = persistReducer(painQuestionsPersistConfig, painQuestionsReducer);

export const store = configureStore({
  reducer: {
    painArea: painAreaReducer,
    painDefi: painDefiReducer,
    painForm: painFormReducer,
    painQuestions: persistedPainQuestionsReducer, 
    painDiagnosis: painDiagnosisReducer,
    treatments: treatmentsReducer,
    userInfo: UserInfo,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
    }),
});
export const persistor = persistStore(store);
