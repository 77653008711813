import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';

const SubscriptionSuccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
  
    useEffect(() => {
      const subscriptionData = localStorage.getItem("subscriptionData");
      if (!subscriptionData) {
        navigate("/subscription");
        return;
      }
    }, [navigate]);
  
    const handleContinue = () => {
      const subscriptionData = localStorage.getItem("subscriptionData");

      if (!subscriptionData) {
        navigate("/subscription");
        return;
      }
      navigate('/');
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
       <div className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 p-8">
  <div className="flex flex-col items-center text-center">
    <div className="mb-4">
      <FaCheckCircle className="w-16 h-16 text-green-500" />
    </div>
    <h2 className="text-2xl font-bold text-gray-900 mb-4">
      {t('subscription.payment.success')}
    </h2>
    <p className="text-gray-600 mb-8">
      {t('subscription.payment.success')}
    </p>
    <button
      onClick={handleContinue}
      className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors"
    >
      {t('continue')}
    </button>
  </div>
</div>
      </div>
    );
  };
  

export default SubscriptionSuccess;
