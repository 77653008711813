import React, { useState } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { api } from '../../api';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe("pk_live_51QbUtW2KT1YZQHBQHfoONQ1nknsO87iOJbkIfZhVAzVv3PmhGvX6QCAkGkMPD6wGgxSMb2vGyl6EozCTdN9cujOD00fbRfAMFu");

const PaymentForm = ({ priceId, onSuccess, onCancel, couponCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      toast.error('Stripe not initialized');
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error('Authentication required');
      return;
    }

    setIsLoading(true);

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }
      const { data: subscriptionData } = await api.post('/createSubscription', {
        stripePricingId: priceId,
        promoCode: couponCode
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (subscriptionData.responseStatus !== 1 || !subscriptionData.response?.subscription) {
        throw new Error(subscriptionData.message || 'Failed to create subscription');
      }

      const clientSecret = subscriptionData.response.subscription.latest_invoice.payment_intent.client_secret;
      if (!clientSecret) {
        throw new Error('No client secret received from the server');
      }

      const { error: confirmError } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/subscription/success`,
        },
        redirect: 'if_required'
      });

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      const subscriptionDetails = {
        subscriptionId: subscriptionData.response.subscription.id,
        startDate: new Date().toISOString(),
        status: subscriptionData.response.subscription.status,
        plan: {
          name: getPlanName(subscriptionData.response.subscription.plan.id),
          price: formatPrice(subscriptionData.response.subscription.plan.amount / 100)
        },
        nextBillingDate: new Date(subscriptionData.response.subscription.current_period_end * 1000).toISOString()
      };

      localStorage.setItem("subscriptionData", JSON.stringify(subscriptionDetails));

      navigate("/subscription/success");
      onSuccess?.();
      
    } catch (err) {
      console.error('Subscription error:', err);
      localStorage.removeItem("subscriptionData");
      toast.error(err.message || 'Subscription failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const getPlanName = (priceId) => {
    const planMap = {
      'price_1Qfbsu2KT1YZQHBQW5s5DyDG': 'Monthly',
      'price_1Qfbvx2KT1YZQHBQc7W0Cn7f': 'Quarterly',
      'price_1Qfbur2KT1YZQHBQH8Q9JxjJ': 'Yearly'
    };
    return planMap[priceId] || 'Unknown Plan';
  };

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto p-6 overflow-y-auto max-h-[500px]">
      <PaymentElement />
      <div className="mt-6 flex justify-between">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={isLoading}
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          disabled={isLoading || !stripe}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
        >
          {isLoading ? t('processing') : t('pay')}
        </button>
      </div>
    </form>
  );
};

export const PaymentModal = ({ priceId, isOpen, onClose, couponCode }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  if (!isOpen || !token) return null;

  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimaryText: '#262626',
    },
  };

  const options = {
    mode: 'subscription',
    amount: 999,
    currency: 'eur',
    appearance,
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full mx-4">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold">  {t('subscription.payment.completePayment')}
          </h2>
        </div>
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm
            priceId={priceId}
            onSuccess={onClose}
            onCancel={onClose}
            couponCode={couponCode}
          />
        </Elements>
      </div>
    </div>
  );
};