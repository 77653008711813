import React, { useState } from 'react'
import { ScreenContainer } from '../../components'
import SubscriptionDetails from '../subscriptionDetails/SubscriptionDetails';
import AccountSettings from '../../components/AccountSettings/AccountSettings';

const UserProfile = () => {
    const [activeTab, setActiveTab] = useState("Account Settings");
    const isAcountSettings = activeTab === "Account Settings";
    const isSubscriptionDetails = activeTab === "Subscription Details";

    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    }

    return (
        <ScreenContainer>
            <div className='border h-full w-full p-[20px] bg-white flex flex-col gap-[20px]'>
                <div className='flex'>
                    <button className={`p-[5px] rounded-s-[10px] ${isAcountSettings ? "bg-[#3B00D1] text-white" : " bg-white text-black border"}`} onClick={() => handleActiveTab("Account Settings")}>Account Settings</button>
                    <button className={`p-[5px] rounded-e-[10px] ${isSubscriptionDetails ? "bg-[#3B00D1] text-white" : " bg-white text-black border"}`} onClick={() => handleActiveTab("Subscription Details")}>Subscription Details</button>
                </div>
                <div className='w-full h-full'>
                    {
                        isAcountSettings ? <AccountSettings /> : <SubscriptionDetails />
                    }
                </div>
            </div>
        </ScreenContainer>
    )
}

export default UserProfile