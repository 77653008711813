import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { checkTrialAndSubscriptionStatus } from "../../utils/checkTrialAndSubscriptionStatus";

const ProtectedRoute = ({ element, redirectTo = "/home/login" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) return;

    const { hasAccess } = checkTrialAndSubscriptionStatus();
    
    if (!hasAccess) {
      localStorage.clear();
      navigate("/subscription");
    }
  }, [navigate, token]);

  if (!token) {
    return <Navigate to={redirectTo} state={{ path: location.pathname }} />;
  }

  const { hasAccess } = checkTrialAndSubscriptionStatus();
  
  if (!hasAccess) {
    return <Navigate to="/subscription" state={{ path: location.pathname }} />;
  }

  return element;
};

export default ProtectedRoute;