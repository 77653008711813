import React from "react";
import InputField from "../InputField/InputField";
import { Form, Formik } from "formik";
import { Button } from "../Button";

const AccountSettings = () => {
  const storedData = localStorage.getItem("formData");
  const formData = storedData ? JSON.parse(storedData) : {};

  return (
    <div className="w-full h-full">
      <Formik
        initialValues={{
          name: formData.name || "",
          email: formData.email || "",
          gender: formData.gender || "",
        }}
        enableReinitialize
        onSubmit={(values) => {
          localStorage.setItem(
            "formData",
            JSON.stringify({ ...formData, ...values })
          );
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-y-2 gap-x-4 mb-[15px]">
              <InputField label="Name" id="name" />
              <InputField label="Email" id="email" />
              <InputField label="Gender" id="gender" />
            </div>
            <Button>Save Changes</Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountSettings;
