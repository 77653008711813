// App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DignosisIntroScreen2 from "./screens/dignosisIntroScreen2/DignosisIntroScreen2";
import Form from "./screens/form/Form";
import WelcomeScreen4 from "./screens/welcome/Welcomescreen4";
import Index from "./screens/body/Index";
import PainDefination from "./screens/painDefination";
import PainLocalization from "./screens/painLocalization";
import DignosisDisclaimer from "./screens/screen8/Dignosisdisclaimer";
import DiagnosisResults from "./screens/DiagnosisResults/DiagnosisResults";
import Treatment from "./screens/Treatment/Treatment";
import TreatmentLoading from "./screens/TreatmentLoading/TreatmentLoading";
import HomeScreen from "./screens/home/HomeScreen";
import Question from "./screens/questions/Question";
import "./App.scss";
import Login from "./screens/authentication/Login";
import Signup from "./screens/authentication/Signup";
import SignupInfo from "./screens/authentication/SignupInfo";
import VerifyOtp from "./screens/authentication/otpVerify/VerifyOtp";
import UserAuth from "./screens/UserAuth/UserAuth";
import ForgotPassword from "./screens/authentication/forgotPassword/ForgotPassword";
import UpdatePassword from "./screens/authentication/forgotPassword/UpdatePassword";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import Chatbot from "./screens/chatbot/Chatbot";
import SubscriptionPlan from "./screens/subscription/SubscriptionPlan";
import SubscriptionSuccess from "./components/SubscriptionSuccess/SubscriptionSuccess";
import UserProfile from "./screens/UserProfile/UserProfile";
import Diagnostics from "./screens/Treatment/Diagnostics";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Authentication Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/info" element={<SignupInfo />} />
        <Route path="/verifyOtp" element={<VerifyOtp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />

        {/* Home Authentication */}
        <Route path="/home/login" element={<Login />} />
        <Route path="/home/signup" element={<Signup />} />
        <Route path="/home/signup/info" element={<SignupInfo />} />
        <Route path="/home/verifyOtp" element={<VerifyOtp />} />
        <Route path="/home/forgotPassword" element={<ForgotPassword />} />
        <Route path="/home/updatePassword" element={<UpdatePassword />} />

        {/* Public Routes */}
        <Route path="/" element={<HomeScreen />} />
        <Route path="/patientForm" element={<Form />} />
        <Route path="/dignosisIntro" element={<DignosisIntroScreen2 />} />
        <Route path="/welcome" element={<WelcomeScreen4 />} />
        <Route path="/body" element={<Index />} />
        <Route path="/painDefination" element={<PainDefination />} />
        <Route path="/painLocalization" element={<PainLocalization />} />
        <Route path="/questions" element={<Question />} />
        <Route path="/importantInformation" element={<DignosisDisclaimer />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/subscription" element={<SubscriptionPlan />} />
        {/* <Route path="/subscription/details" element={<SubscriptionDetails />} /> */}
        <Route path="/subscription/success" element={<SubscriptionSuccess />} />
        {/* Protected Routes */}
        <Route
          path="/diagnosis"
          element={<ProtectedRoute element={<DiagnosisResults />} />}
        />
        <Route path="/userProfile" element={
          <UserAuth>
            <UserProfile />
          </UserAuth>
        } />
        <Route
          path="/treatment/*"
          element={
            <ProtectedRoute
              element={
                <UserAuth>
                  <Treatment />
                </UserAuth>
              }
            />
          }
        />
        <Route
          path="/treatmentLoading"
          element={
            <UserAuth>
              <TreatmentLoading />
            </UserAuth>
          }
        />
        <Route
          path="/diagnostics/*"
          element={
            <ProtectedRoute
              element={
                <UserAuth>
                  <Diagnostics/>
                </UserAuth>
              }
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
