import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";
import "./TreatmentVideo.scss";
import { Loader, ReturnButton } from "..";

const durationToSeconds = (duration) => {
  const [minutes, seconds] = duration.split(":");
  return parseInt(minutes) * 60 + parseInt(seconds);
};

export const TreatmentVideo = ({ duration, onReturn, treatmentUrl, thumbnail }) => {
  const videoRef = useRef(null);
  const [videoTime, setVideoTime] = useState(durationToSeconds(duration));
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [startVideo, setStartVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    let frameId;
    const updateTime = () => {
      if (videoRef.current && startVideo) {
        setCurrentTime(videoRef.current.currentTime);
        frameId = requestAnimationFrame(updateTime);
      }
    };
    frameId = requestAnimationFrame(updateTime);

    return () => cancelAnimationFrame(frameId);
  }, [startVideo]);

  const videoHandler = (control) => {
    if (!videoRef.current) return;

    if (control === "play") {
      videoRef.current.play();
      setIsPlaying(true);
      setStartVideo(true);
    } else if (control === "pause") {
      videoRef.current.pause();
      setIsPlaying(false);
      setStartVideo(false);
    } else if (control === "play-from-start") {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setCurrentTime(0);
      setIsPlaying(true);
      setStartVideo(true);
    }
  };

  const onLoadedMetadata = () => {
    setLoading(false);
    setVideoTime(videoRef.current.duration);
  };

  const barWidth = `calc(${(currentTime / videoTime) * 100}% - 11px)`;

  return (
    <div className="TreatmentVideoPlayer">
      <div className="TreatmentVideoContainer">
        <video
          playsInline  // Enables smoother playback on mobile
          ref={videoRef}
          className="TreatmentVideo"
          src={treatmentUrl}
          poster={thumbnail}
          autoPlay
          preload="metadata"  // Loads only metadata to prevent excessive buffering
          onLoadedMetadata={onLoadedMetadata}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>

      {loading && <Loader className="TreatmentVideoLoader" />}

      <div className="TreatmentVideoDurationBarContainer">
        <div className="TreatmentVideoDurationBar">
          <div className="TreatmentVideoDurationBarProgress" style={{ width: barWidth }} />
        </div>
      </div>

      {/* Mobile Video Controls */}
      <div className="MobileVideoControls">
        <div
          className={cx("TreatmentButtons", {
            TreatmentButtonLoading: loading,
          })}
        >
          <div
            className="TreatmentButtonContainer"
            onClick={() => videoHandler(isPlaying ? "pause" : "play")}
          >
            <img className={isPlaying ? "PauseButton" : "PlayButton"} alt="" />
          </div>
          <div className="TreatmentButtonContainer" onClick={() => videoHandler("play-from-start")}>
            <img className="PlayFromStartButton" alt="" />
          </div>
        </div>
      </div>

      {/* Desktop Video Controls */}
      <div className="TreatmentVideoControls">
        <div className="md:flex hidden items-center">
          <div
            className="TreatmentButtonContainer"
            onClick={() => videoHandler(isPlaying ? "pause" : "play")}
          >
            <img className={isPlaying ? "PauseButton" : "PlayButton"} alt="" />
          </div>
          <div className="TreatmentButtonContainer" onClick={() => videoHandler("play-from-start")}>
            <img className="PlayFromStartButton" alt="" />
          </div>
        </div>
        <ReturnButton className="TreatmentVideoReturnButton" onClick={onReturn} />
      </div>
    </div>
  );
};
