import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { useNavigate } from "react-router-dom";
import { getDiagonsis } from "../../redux/questions/painQuestionsSlice";
import {
  ScreenContainer,
  Text,
  DiagnosisProgressCard,
  DiagnosisDescriptionCard,
} from "../../components";
import "./DiagnosisResults.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { fetchTreatments } from "../../redux/APIs";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";

const DiagnosisResults = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const diagnosis = useSelector(getDiagonsis);
  const userData = useSelector((state) => state.userInfo.userData);
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedCard) {
      dispatch(setUserData({ diagnosisIds: selectedCard }));
    }
  }, [selectedCard, dispatch]);

  useEffect(() => {
    if (userData && userData.diagnosisIds.length > 0) {
      sendPayload(userData);
    }
    // eslint-disable-next-line
  }, [userData]);

  const onClick = () => {
    sendPayload(userData);
    fetchTreatments(
      dispatch,
      diagnosis[selectedCard].diagnosticId,
      diagnosis[selectedCard].possibleDiagnostic
    );
    if (token) {
      Navigate("/treatmentLoading");
    } else {
      Navigate("/login");
    }
  };

  const sendPayload = async (userData) => {
    try {
       await api.post(apiEndpoint.addUserTreatment, {
        painAreaId: userData?.painAreaId || "",
        painBehaviorId: userData?.painBehaviorId,
        painDefinitionId: userData?.painDefinitionId,
        questionAnswers: userData?.questionAnswers,
        diagnosisIds: diagnosis[selectedCard].diagnosticId,
        age: userData?.age,
        gender: userData?.gender,
      });
    } catch (error) {}
  };

  return (
    <ScreenContainer>
      <div className="ResultsTopTitleBar">
        <Text variant="Heading3">{t("diagnosisResultsHeading")}</Text>
      </div>
      <div className="ResultsContent">
        <div className="ResultsDescriptionContainer">
          <div className="ResultsCardContent">
            {diagnosis && diagnosis?.length
              ? diagnosis?.map((element, index) => (
                  <DiagnosisProgressCard
                    key={element.possibleDiagnostic}
                    element={element}
                    index={index}
                    onClick={(id) => setSelectedCard(id)}
                    selectedCard={selectedCard}
                  />
                ))
              : null}
          </div>
        </div>
        <div className="ResultsDivider" />
        <div
          className="ResultsDescriptionContainer"
          onClick={() => {
            setSelectedCard(null);
          }}
        >
          <div
            className={cx("ResultsDescriptionContent", {
              ResultsDescriptionContentShow: selectedCard !== null,
            })}
          >
            {selectedCard !== null && (
              <DiagnosisDescriptionCard
                index={selectedCard}
                selectedCard={diagnosis[selectedCard]}
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
};

export default DiagnosisResults;
