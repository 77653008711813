import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VerticalNavBar, ScreenContainer } from "../../components";
import DiagnosticIcon from "../../assets/images/diagnostics-icon.svg";
import TreatmentIcon from "../../assets/images/treatment-icon.svg";
import MenuIcon from "../../assets/images/menu-icon.svg";
import { fetchDiagnosis, fetchTreatments } from "../../redux/APIs";
import "./Diagnostics.scss";
import { TreatmentHeader } from "./TreatmentHeader";
import { useDispatch } from "react-redux";
import { getLanguage } from "../../redux/APIs/utils";


const Diagnostics = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [diagnoses, setDiagnoses] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const getDiagnoses = async () => {
      const response = await fetchDiagnosis();
      if (response) {
        setDiagnoses(response.data.response);
        setIsLoading(false);
      }
    };
    getDiagnoses();
  }, []);

  const toggleMenu = () => setMenuOpen((state) => !state);

  const verticalNavbarConfig = {
    title: t("actions"),
    buttons: [
      {
        svg: DiagnosticIcon,
        label: t("diagnostics"),
        selected: true,
        onClick: () => navigate("/diagnostics"),
      },
      {
        svg: TreatmentIcon,
        label: t("treatments"),
        selected: false,
        onClick: () => navigate("/treatment"),
      },
    ],
  };


  const menu = (
    <div className="Menu-Button" onClick={toggleMenu}>
      <img className="Menu-Icon" alt="" src={MenuIcon} />
    </div>
  );

  const startTreatment = (id, diagnosesName) => {
    fetchTreatments(dispatch, id, diagnosesName);
    navigate("/treatment");
  }

  return (
    <ScreenContainer headerRightArea={menu}>
  <div className="Diagnostics-Container">
    <VerticalNavBar
      config={verticalNavbarConfig}
      toggleMenu={toggleMenu}
      menuOpen={menuOpen}
    />
    <div className="Diagnostics-Right-Section">
      <TreatmentHeader />
      {isLoading ? (
        <p className="Diagnostics-loading">loading...</p>
      ) : (
        <div>
          <div className="Diagnostics-Right-Section-NavBar-Border" />
          <div className="Diagnostics-Content">
            {diagnoses?.map((diagnosis) => (
              <div key={diagnosis?._id} className="Diagnosis-Card">
                <div className="Diagnosis-Card-Heading">
                <h2>{(getLanguage() === "es")? 
                (diagnosis?.diagnosisId?.diagnosisNameEs):
                (diagnosis?.diagnosisId?.diagnosisName)}</h2>
                </div>

                <div className="Diagnosis-Card-Image">
                <img src={diagnosis?.painBehaviorId?.imageUrl} alt={
                  (getLanguage() === "es")? 
                (diagnosis?.diagnosisId?.diagnosisNameEs):
                (diagnosis?.diagnosisId?.diagnosisName)} />
                </div>

                <div className="Diagnosis-Card-Info">
                <p>{((getLanguage() === "es")? 
                (diagnosis.diagnosisId?.diagnosisDescEs):
                (diagnosis.diagnosisId?.diagnosisDesc)).slice(0,120)+"..."}</p>
                <div className="Diagnosis-Details">
                  <span><strong>{`${t("painArea")}:`}</strong> {
                  (getLanguage() === "es")? 
                (diagnosis.painAreaId?.nameEs):
                (diagnosis.painAreaId?.name)}</span>
                  <span><strong>{`${t("behavior")}:`}</strong> {
                (getLanguage() === "es")? 
                (diagnosis.painBehaviorId?.nameEs):
                (diagnosis.painBehaviorId?.name)
                }</span>
                  <span><strong>{`${t("definition")}:`}</strong> {
                  (getLanguage() === "es")? 
                  (diagnosis.painDefinitionId?.nameEs):
                  (diagnosis.painDefinitionId?.name)
                  }</span>
                </div>
                </div>
                <button className="Diagnosis-Card-Button" onClick={()=>startTreatment(diagnosis.diagnosisId._id, diagnosis.diagnosisId.diagnosisName)}>
                {t("treatmentButton")}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
</ScreenContainer>
  );
};

export default Diagnostics;
