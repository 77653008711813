import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from '../Text';
import './FooterLogo.scss';

export const FooterLogo = () => {
  const { t } = useTranslation();
  return (
    <div className="FooterLogo">
      <div className="FooterLogoLine1">
        <Text className={"SunndioHealth-Year"} variant={'Paragraph1'}>Sunndio health</Text>
        <Text className={"Copyright"} variant={'Paragraph1'}>&copy;</Text>
        <Text className={"SunndioHealth-Year"} variant={'Paragraph1'}> 2025</Text>
      </div>
      <Text className={"FooterLogoLine2"} variant={'Paragraph1'}>{t("footerText")}</Text>
    </div>
  );
};
