import React, { useState, useEffect } from "react";
import { Button, Text } from "../../components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";

const SubscriptionDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const [trialInfo, setTrialInfo] = useState(null);
  const [error, setError] = useState(null);

  const getPlanInterval = (interval, intervalCount) => {
    if (interval === 'year') return 'Yearly';
    if (interval === 'month') {
      return intervalCount === 3 ? 'Quarterly' : 'Monthly';
    }
    return interval;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const token = localStorage.getItem("token");
        const formData = JSON.parse(localStorage.getItem("formData") || "{}");

        if (!token) {
          console.log('Missing auth token');
          navigate("/login");
          return;
        }


        if (formData.createdAt) {
          const creationDate = new Date(formData.createdAt);
          const trialPeriod = 14 * 24 * 60 * 60 * 1000;
          const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
          const currentDate = new Date();

          if (currentDate <= trialEndDate) {
            const daysLeft = Math.ceil((trialEndDate - currentDate) / (1000 * 60 * 60 * 24));
            setTrialInfo({
              daysLeft,
              endDate: trialEndDate,
              startDate: creationDate
            });
          }
        }

        const subscriptionResponse = await api.post(apiEndpoint.getSubscriptionDetails, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (subscriptionResponse.data.responseStatus === 1) {
          const subscriptionData = subscriptionResponse.data.response;
          const planInterval = getPlanInterval(
            subscriptionData.plan.interval,
            subscriptionData.plan.interval_count
          );

          const subscriptionDetails = {
            subscriptionId: subscriptionData.id,
            startDate: subscriptionData.start_date,
            status: subscriptionData.status,
            plan: {
              name: `${planInterval} Plan`,
              price: formatPrice(subscriptionData.plan.amount / 100),
              interval: planInterval
            },
            nextBillingDate: subscriptionData.current_period_end * 1000
          };

          setSubscription(subscriptionDetails);
          localStorage.setItem("subscriptionData", JSON.stringify(subscriptionDetails));
        }

      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
        if (err.response?.status === 401) {
          navigate("/login");
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [navigate]);

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");

      const response = await api.post(apiEndpoint.cancelSubscription,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.responseStatus === 1) {
        localStorage.removeItem("subscriptionData");
        console.log('Subscription cancelled successfully', response.data);
        navigate("/subscription");
      } else {
        throw new Error(response.data.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      toast.error(t("subscriptionCancelFailed"));
      console.error('Cancel subscription error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      // <ScreenContainer>
      //   <div className="w-full h-full bg-white flex flex-col items-center justify-center p-8">
      //     <Text variant="h3" className="mb-4">{t("loading")}</Text>
      //   </div>
      // </ScreenContainer>
      <div className="w-full h-full bg-white flex flex-col items-center justify-center p-8">
        <Text variant="h3" className="mb-4">{t("loading")}</Text>
      </div>
    );
  }

  if (error) {
    return (
      // <ScreenContainer>
      //   <div className="w-full h-full bg-white flex flex-col items-center justify-center p-8">
      //     <Text variant="h3" className="mb-4 text-red-600">
      //       {error}
      //     </Text>
      //     <Button onClick={() => navigate("/subscription")} className="mt-4">
      //       {t("returnToPlans")}
      //     </Button>
      //   </div>
      // </ScreenContainer>
      <div className="w-full h-full bg-white flex flex-col items-center justify-center p-8">
        <Text variant="h3" className="mb-4 text-red-600">
          {error}
        </Text>
        <Button onClick={() => navigate("/subscription")} className="mt-4">
          {t("returnToPlans")}
        </Button>
      </div>
    );
  }

  return (
    // <ScreenContainer>
    //   <div className="w-full h-full bg-white flex flex-col items-center p-8">
    //     <Text variant="h1" className="mb-8">
    //       {t("subscriptionDetails")}
    //     </Text>

    //     {trialInfo && (
    //       <div className="max-w-2xl w-full bg-blue-50 rounded-lg p-6 mb-8">
    //         <Text variant="h3" className="mb-4">
    //           {t("freeTrial")}
    //         </Text>
    //         <div className="space-y-4">
    //           <div className="flex justify-between">
    //             <span>{t("status")}:</span>
    //             <span className="font-semibold text-blue-600">
    //               {t("active")}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("startDate")}:</span>
    //             <span className="font-semibold">
    //               {trialInfo.startDate.toLocaleDateString()}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("endDate")}:</span>
    //             <span className="font-semibold">
    //               {trialInfo.endDate.toLocaleDateString()}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("daysRemaining")}:</span>
    //             <span className="font-semibold text-blue-600">
    //               {trialInfo.daysLeft}
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     )}

    //     {subscription && (
    //       <div className="max-w-2xl w-full bg-gray-50 rounded-lg p-6 mb-8">
    //         <Text variant="h3" className="mb-4">
    //           {t("currentPlan")}
    //         </Text>
    //         <div className="space-y-4">
    //           <div className="flex justify-between">
    //             <span>{t("plan")}:</span>
    //             <span className="font-semibold">
    //               {subscription.plan.name} - {subscription.plan.price}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("status")}:</span>
    //             <span className="font-semibold text-green-600">
    //               {subscription.status}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("startDate")}:</span>
    //             <span className="font-semibold">
    //               {new Date(subscription.startDate * 1000).toLocaleDateString()}
    //             </span>
    //           </div>
    //           <div className="flex justify-between">
    //             <span>{t("nextBilling")}:</span>
    //             <span className="font-semibold">
    //               {new Date(subscription.nextBillingDate).toLocaleDateString()}
    //             </span>
    //           </div>
    //         </div>

    //         <Button
    //           onClick={handleCancelSubscription}
    //           disabled={isLoading}
    //           className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md mt-6"
    //         >
    //           {isLoading ? t("cancelling") : t("cancelSubscription")}
    //         </Button>
    //       </div>
    //     )}

    //     {!subscription && !trialInfo && (
    //       <div className="max-w-2xl w-full flex flex-col items-center">
    //         <Text variant="h3" className="mb-4">
    //           {t("subscription.noActiveSubscription")}
    //         </Text>
    //         <Button onClick={() => navigate("/subscription")} className="mt-4">
    //           {t("subscription.viewPlans")}
    //         </Button>
    //       </div>
    //     )}
    //   </div>
    // </ScreenContainer>
    <div className="w-full h-full bg-white flex flex-col items-center p-8">
      <Text variant="h1" className="mb-8">
        {t("subscriptionDetails")}
      </Text>

      {trialInfo && (
        <div className="max-w-2xl w-full bg-blue-50 rounded-lg p-6 mb-8">
          <Text variant="h3" className="mb-4">
            {t("freeTrial")}
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between">
              <span>{t("status")}:</span>
              <span className="font-semibold text-blue-600">
                {t("active")}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("startDate")}:</span>
              <span className="font-semibold">
                {trialInfo.startDate.toLocaleDateString()}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("endDate")}:</span>
              <span className="font-semibold">
                {trialInfo.endDate.toLocaleDateString()}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("daysRemaining")}:</span>
              <span className="font-semibold text-blue-600">
                {trialInfo.daysLeft}
              </span>
            </div>
          </div>
        </div>
      )}

      {subscription && (
        <div className="max-w-2xl w-full bg-gray-50 rounded-lg p-6 mb-8">
          <Text variant="h3" className="mb-4">
            {t("currentPlan")}
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between">
              <span>{t("plan")}:</span>
              <span className="font-semibold">
                {subscription.plan.name} - {subscription.plan.price}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("status")}:</span>
              <span className="font-semibold text-green-600 capitalize">
                {subscription.status}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("startDate")}:</span>
              <span className="font-semibold">
                {new Date(subscription.startDate * 1000).toLocaleDateString()}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{t("nextBilling")}:</span>
              <span className="font-semibold">
                {new Date(subscription.nextBillingDate).toLocaleDateString()}
              </span>
            </div>
          </div>

          <Button
            onClick={handleCancelSubscription}
            disabled={isLoading}
            className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md mt-6"
          >
            {isLoading ? t("cancelling") : t("cancelSubscription")}
          </Button>
        </div>
      )}

      {!subscription && !trialInfo && (
        <div className="max-w-2xl w-full flex flex-col items-center">
          <Text variant="h3" className="mb-4">
            {t("subscription.noActiveSubscription")}
          </Text>
          <Button onClick={() => navigate("/subscription")} className="mt-4">
            {t("subscription.viewPlans")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;