import React, { useState } from "react";
import { Button } from "../Button";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";

const Coupon = ({setDiscount, setCouponCodeValue}) => {
  const [couponCode, setCouponCode] = useState("");

  const handleInputChange = (event) => {
    setCouponCode(event.target.value); 
  };

  const onClickFun = () => {

    const getCouponData = async () => {
      try {
        const response = await api.post(apiEndpoint.couponCode, {
          promoCode: couponCode,
        });
        setDiscount(response.data.response.discount);
        setCouponCodeValue(couponCode);
      } catch (error) {
        setDiscount(0);
        setCouponCodeValue(null)
      }
    };

    getCouponData();
    setCouponCode('');
  };

  return (
    <div className="flex">
      <div className="flex w-full max-w-sm items-center space-x-1 border border-gray-200 hover:border-blue-400 transition duration-300 ease-in rounded-2xl py-2 px-4 mb-4">
        <input
          type="text"
          value={couponCode}
          placeholder="If you have a coupon..."
          className="  px-2 py-2 rounded-lg placeholder:text-left focus:outline-none placeholder:text-sm"
          onChange={handleInputChange}
        />
        <Button className="py-2 px-5" onClick={() => onClickFun()}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default Coupon;
