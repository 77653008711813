export const checkTrialAndSubscriptionStatus = () => {
    const formData = JSON.parse(localStorage.getItem("formData") || "{}");
    const subscriptionData = JSON.parse(localStorage.getItem("subscriptionData") || "null");
    const createdOn = formData.createdAt;
  
    // Check if user has active subscription
    const hasActiveSubscription = subscriptionData?.status === "active" && 
      (!subscriptionData.cancelAt || new Date(subscriptionData.cancelAt) > new Date());
  
    // Check trial status
    const checkTrialStatus = () => {
      if (!createdOn) return false;
      
      const creationDate = new Date(createdOn);
      const trialPeriod = 14 * 24 * 60 * 60 * 1000;
      const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
      const currentDate = new Date();
      
      return currentDate.getTime() <= trialEndDate.getTime();
    };
  
    const isTrialActive = checkTrialStatus();
  
    return {
      hasActiveSubscription,
      isTrialActive,
      hasAccess: hasActiveSubscription || isTrialActive
    };
  };