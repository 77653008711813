import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";

export const fetchDiagnosis = async () => {
  try {
    const response = await api.get(apiEndpoint.userDiseases);
    return response;
  } catch (error) {
    console.log(error);
  }
};
